import React from "react";
import ContentContainer from "../../../components/container/ContentContainer";
import ContactExpert from "../../../components/controls/ContactExpert";
import Header from "../../../components/sections/Header";
import HeadData from "../../../data/HeadData";
import { useStaticQuery, graphql } from "gatsby";
import TitleDiv from "../../../components/controls/TitleDiv";
import ButtonAction from "../../../components/controls/ButtonAction";
import Layout from "../../../components/layout/Layout";

const MDFPage = () => {
  const heroImage = useStaticQuery(graphql`
    {
      backGround: file(relativePath: { eq: "mdf-banner.png" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            formats: [AUTO, WEBP]
            quality: 100
          )
        }
      }
    }
  `);
  const path = ["MDF Wall Panels in Southern California"];

  return (
    <Layout>
      <div className="w-full max-w-full relative overflow-hidden">
        <HeadData 
          title="MDF Wall Panels - So Cal" 
          description="Upgrade your room decor with stylish and durable MDF wall panels. Get inspired by the latest designs and find the best deals on high-quality panels now."
        />
        <Header />

        <TitleDiv
          parentText="Products"
          title="MDF Wall Panels in Southern California"
          className="mb-8"
          path={path}
        />

        <ContentContainer
          className="w-full max-w-content mx-5 transform bg-center flex items-center justify-center"
          opacityClassName="bg-opacity-60"
          image={heroImage.backGround}
        >
          <div className="text-white my-20 transform text-center text-3xl space-y-4 w-full max-w-xl">
            <p className="font-bold">MDF</p>
            <p className="text-base pt-5">
              MDF is an engineered wood product created by breaking down hardwood
              or softwood residuals into wood fibers combining it with wax and a
              resin binder, and forming panels by applying high pressure and
              temperature.
            </p>
            <ButtonAction to="/contact" className="mt-10 bg-actionBlue px-16">
              Request free quote
            </ButtonAction>
          </div>
        </ContentContainer>

        <ContentContainer className="bg-gray5 text-center mt-7 py-12">
          <h2>Collection</h2>
        </ContentContainer>
        <ContentContainer className="bg-gray2 py-16 flex flex-col items-start sm:items-center gap-y-11">
          <p>Available in 5.2, 1/4″, 3/8″, 7/16″, 3/4″, 1/2″, 5/8:, 1″</p>
          <p>Please call us for the most current inventory and availability.</p>
          <p>ACX & CDX</p>
        </ContentContainer>

        <ContentContainer className="bg-gray2 py-14 xl:px-60 my-7 flex flex-col items-center text-center font-bold">
          <h3 className="px-5 sm:px-10">
            National Wood Products stocks a variety of MDF panels in premium,
            standard & ultralight. This product is called an engineered or
            manufactured wood.
          </h3>
        </ContentContainer>

        <ContactExpert />
      </div>
    </Layout>
  );
};

export default MDFPage;
